// 学历 
const Degreetype = {
    1: () => { return '小学' },
    2: () => { return '初中' },
    3: () => { return '高中' },
    4: () => { return '中专' },
    5: () => { return '大专' },
    6: () => { return '本科' },
    7: () => { return '硕士' },
    8: () => { return '博士' }
}
// 求职状态
const JobStatuspeType = {
    1: () => { return '离职-找工作' },
    2: () => { return '在职-观望中' },
    3: () => { return '在职-不跳槽' }
}

// 联系方式文本
const contactMethodType = {
    'P': () => { return '联系手机' },
    'W': () => { return '微信' },
    'Q': () => { return 'QQ' },
    'M': () => { return '邮箱' },
}
const CommodityTypeClass = {
    // 获取学历文本
    getDegreetype(type) {
        return Degreetype[type] ? Degreetype[type]() : ''
    },
    // 获取求职状态文本
    getJobStatuspeType(type) {
        return JobStatuspeType[type] ? JobStatuspeType[type]() : ''
    },
    // 获取联系方式文本
    getContactMethodType(type) {
        return contactMethodType[type] ? contactMethodType[type]() : ''
    },
}
export {
    CommodityTypeClass
}
