<template>
    <div class="ResumeDetails">
        <div class="head f-c-b">
            <div class="LeftPart f-c">
                <img class="logo" src="@/assets/Community/logo.png" alt="">
                <img class="shiliaologo" src="@/assets/Community/shiliaologo.png" alt="">
            </div>
            <el-breadcrumb class="f-c-e" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">
                    <img style="width: 16px;height: 14px;" src="@/assets/home.png" alt="">
                    <span> 当前位置： 首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/CommunityIndex' }">社区</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/TalentRecruitmentIndex' }">人才招聘</el-breadcrumb-item>
                <el-breadcrumb-item>个人中心</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="mian flex">
            <div class="leftpart f-cl-c-c">
                <img class="w-100 h-100 borderRa-50 mb-20" :src="userAvatarUrl" alt="">
                <div class="fs-30 color-333333 mb-20">{{ resumeDetails.name }}</div>
                <div class="flex">
                    <img class="w-22 h-20 mr-10" src="@/assets/Community/jobIcon.png" alt="">
                    <span class="fs-20">{{ resumeDetails.position }}</span>
                </div>
                <div class="mt-80 box">
                    <span class="common">{{ resumeDetails.workAge }}年</span>
                    <span class="common">|</span>
                    <span class="common">{{ resumeDetails.topDegree ?
                    resumeDetails.topDegreeVlaue : '未填写' }}</span>
                    <span class="common">|</span>
                    <span class="common">{{ resumeDetails.salary }}</span>
                </div>
                <div class="mt-45 box">{{ resumeDetails.jobHuntVlaue }}</div>
                <div class="border"></div>
            </div>
            <div class="rightpart ml-60">
                <div class="title flex mb-30">
                    <div class="pillar mr-10"></div><span class="fs-24">基本信息</span>
                </div>
                <div class="infoBox">
                    <div class="mb-30 fs-16">
                        <span>年龄</span>
                        <span class="ml-40">{{ resumeDetails.age }}</span>
                    </div>
                    <div class="mb-30">
                        <span>联系电话</span>
                        <span class="ml-40">{{ resumeDetails.contactValue }}</span>
                    </div>
                    <div>
                        <span>所在城市</span>
                        <span class="ml-40">{{ resumeDetails.workLocation }}</span>
                    </div>
                </div>
                <div class="title flex mb-30">
                    <div class="pillar mr-10"></div><span class="fs-24">工作经历</span>
                </div>
                <div class="infoBox">

                    <div v-if="forumExperience && forumExperience.length != 0">
                        <div class="f-c-b" v-for="(item, index) in forumExperience" :key="index">
                            <div>
                                <span>{{ item.intoDate }}</span>
                                <span> - </span>
                                <span>{{ item.outDate }}</span>
                            </div>
                            <span>{{ item.corporateName }}</span>
                            <span>{{ item.position }}</span>
                        </div>
                    </div>
                    <div v-else>无</div>
                </div>
                <div class="title flex mb-30">
                    <div class="pillar mr-10"></div><span class="fs-24">教育背景</span>
                </div>
                <div class="infoBox">
                    <div v-if="forumEducation && forumEducation.length != 0">
                        <div class="f-c-b" v-for="(item, index) in forumEducation" :key="index">
                            <div>
                                <span>{{ item.intakeDate }}</span>
                                <span> - </span>
                                <span>{{ item.graduateDate }}</span>
                            </div>
                            <span>{{ item.schoolName }}</span>
                            <span>{{ item.speciality }}</span>
                        </div>
                    </div>
                    <div v-else>无</div>
                </div>
                <div class="title flex mb-30">
                    <div class="pillar mr-10"></div><span class="fs-24">自我评价</span>
                </div>
                <div class="infoBox">
                    <span>{{ resumeDetails.selfEvaluation }}</span>
                </div>
            </div>
        </div>
        <div class="btnBox f-c-c">
            <div class="btnStyle1" @click="deleteFlagChange()">{{ resumeDetails.deleteFlag == 'N' ? '不开放简历' : '开放简历' }}
            </div>
            <div class="btnStyle1 ml-60 mr-60" @click="modifyResume()">修改简历</div>
            <div class="btnStyle2" @click="deleteResume()">删除简历</div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { CommodityTypeClass } from "@/utils/InviteJob.js";
export default {
    name: 'ResumeDetails',
    data() {
        return {
            resumeDetails: {},
            forumEducation: [],
            forumExperience: [],
        }
    },
    computed: {
        ...mapState({
            userAvatarUrl: 'userAvatarUrl',
        }),
    },
    created() {
        let { id } = JSON.parse(this.$UrlEncode.decode(this.$route.query.key));
        this.forumResumeMyListDetails(id);
    },
    methods: {
        // 简历详情
        forumResumeMyListDetails(resumeId) {
            let that = this;
            that.$http.forumResumeMyListDetails({
                resumeId
            }).then(res => {
                if (res.code == 200) {
                    let data = res.data;
                    data.topDegreeVlaue = CommodityTypeClass.getDegreetype(data.topDegree);
                    data.jobHuntVlaue = CommodityTypeClass.getJobStatuspeType(data.jobHunt);
                    that.resumeDetails = res.data;
                    that.forumEducation = res.data.forumEducation;
                    that.forumExperience = res.data.forumExperience;
                }
            })
        },
        // 修改简历状态
        deleteFlagChange() {
            let that = this,
                tip = '',
                { resumeId, deleteFlag } = that.resumeDetails,
                saveState = deleteFlag == 'N' ? 'S' : 'N';
            if (deleteFlag == 'N') {
                tip = '将简历设置为不公开后，boss们将无法查看到您的简历哦'
            } else {
                tip = '将简历设置为公开后，boss们将能查看您的简历哦'
            }

            that.$confirm(tip, '提示').then(() => {
                that.$http.handOffForumResumeState({
                    userId: parseInt(that.$store.state.userId),
                    resumeId,
                    saveState
                }).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success');
                        that.forumResumeMyListDetails(resumeId);
                        that.$router.go(-1);
                    }
                })
            }).catch(() => { })
        },
        // 删除简历
        deleteResume() {
            let that = this,
                { resumeId } = that.resumeDetails;
            that.$confirm('确定删除简历吗？', '提示').then(() => {
                that.$http.removeForumResume({
                    resumeId,
                }).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success');
                        that.$router.go(-1);
                    }
                })
            }).catch(() => { })
        },
        // 修改简历
        modifyResume() {
            let resumeDetails = this.resumeDetails;
            resumeDetails.forumEducation.forEach((item) => {
                delete item.resumeId;
                delete item.educationId;
            })
            resumeDetails.forumExperience.forEach((item) => {
                delete item.experienceId;
                delete item.resumeId;
            })
            // console.log('resumeDetails',resumeDetails);
            this.$router.push({
                name: 'ControlsResume',
                query: {
                    key: this.$UrlEncode.encode(JSON.stringify({
                        resumeDetails: resumeDetails,
                        type: 'modify'
                    }))
                }
            })
        }

    },
}

</script>
<style lang='less' scoped>
.ResumeDetails {
    .head {
        padding: 20px 0;
        border-bottom: 1px solid #F7F7F8;

        .LeftPart {
            width: 55px;
            cursor: pointer;

            .logo {
                width: 53px;
                height: 53px;
                margin-right: 18px;
            }

            .shiliaologo {
                width: 77px;
                height: 38px;
            }

        }

        .el-breadcrumb ::v-deep .el-breadcrumb__inner {
            color: #666666 !important;
            font-weight: 400 !important;
            font-size: 14px;
        }

        .el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
            color: #0363FA !important;
            font-weight: 400 !important;
            font-size: 14px;
        }
    }

    .mian {
        margin-top: 30px;

        .leftpart {
            padding: 70px 90px 130px;
            width: 160px;
            height: 380px;
            position: relative;

            .border {
                position: absolute;
                border-top: 1px solid #F5F6F7;
                width: 345px;
                height: 1px;
                top: 300px;
            }

            .box {
                width: 160px;
                height: 33px;
                background: #026EF4;
                opacity: 0.5;
                border-radius: 5px;
                font-size: 16px;
                color: #FFF;
                line-height: 33px;
                text-align: center;
            }
        }

        .rightpart {
            width: 840px;

            .title {
                .pillar {
                    width: 4px;
                    height: 22px;
                    background: #0363FA;
                }
            }

            .infoBox {
                padding: 30px 70px;
                width: 700px;
                // height: 100px;
                background: #F4F7FB;
                border-radius: 10px;
                margin-bottom: 40px;
            }

        }
    }

    .btnBox {
        .btnStyle1 {
            width: 160px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            color: #FFF;
            border-radius: 10px;
            background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
            cursor: pointer;
        }

        .btnStyle2 {
            width: 160px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            color: #026EF4;
            border-radius: 10px;
            background: #E0E9F5;
            cursor: pointer;
        }
    }
}
</style>